import * as React from "react";
import { useEffect } from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { graphql } from "gatsby";
import { LoginForm } from "../components/utilities/form";
import Layout from "../components/layouts/gated-layout";
import Advertisement from "../components/layouts/advertisement-column";
import Logo from "../images/asterra-logo-color.svg";
import axios from "axios";
import { navigate } from "gatsby";
import {
  login,
  setSnackBarMsg,
  toggleShowSnackBar,
} from "../store/ducks/general";
import { useSelector, useDispatch } from "react-redux";
import { Snackbar, Alert } from "@mui/material";
import icon from "../images/icon.png";

const style = {
  image: {
    width: "20px",
    height: "20px",
    marginRight: "10px",
  },
  alert: {
    backgroundColor: "#000",
    color: "#fff",
    fontFamily: "sans-serif",
    border: ".5px solid rgba(255,255,255,0.25)",
  },
};

const Container = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  a.standard-text-link {
    color: ${(props) => props.theme.variables.color};
    text-decoration: underline;
    &:hover {
      color: #ff4d1d;
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    grid-template-columns: 1fr;
  }
`;

const LoginColumn = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .logo {
    position: absolute;
    inset: 3rem auto auto 3rem;
  }
  .login {
    padding: 3rem;
    max-width: 75%;
    width: 75%;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    .login {
      max-width: 100%;
      width: 100%;
    }
  }
`;

const Index = ({ location }) => {
  //const [authState, setAuthState] = React.useContext(AuthContext);
  // Check if user is coming from My Account page. If so, and user is attempting to log in with another account fill in that username and password info.

  useEffect(() => {
    if (typeof window !== "undefined") {
      localStorage.removeItem("auth");
      localStorage.removeItem("subProjectData");
      localStorage.removeItem("subProjects");
      localStorage.removeItem("subMenu");
      localStorage.removeItem("ally-supports-cache");
    }
  }, []);

  const dispatch = useDispatch();
  const loginValues = useSelector((state) => state.general.loginValues);
  const showSnackBar = useSelector((state) => state.general.showSnackBar);
  const snackBarMsg = useSelector((state) => state.general.snackBarMsg);

  const [loginCredentials, setLoginCredentials] = React.useState({
    username: null,
    password: null,
  });

  /*
  React.useEffect(() => {
    if (location.state !== null) {
      setLoginCredentials({
        username: location.state.user_name,
        password: location.state.password,
      });
    }
  }, [location.state]);
  */

  const { languages, changeLanguage } = useI18next();

  const getLanguageFromLocalStorage = () => {
    const storedItem = window.localStorage.getItem("persist:root");
    if (storedItem) {
      const parsedItem = JSON.parse(storedItem);
      const generalData = JSON.parse(parsedItem.general);
      const storedLanguage = generalData.language;
      if (generalData && storedLanguage) {
        changeLanguage(storedLanguage);
      }
    }
    return;
  };

  // check if user logged
  useEffect(() => {
    getLanguageFromLocalStorage();
    if (loginValues) {
      if (loginValues.userId != null) {
        window.localStorage.setItem("auth", JSON.stringify(loginValues));
        navigate("home");
      }
    }
  }, [languages, loginValues && loginValues.userId]);

  const HandleLogin = async ({ user_name, password, remember }) => {
    try {
      const { data } = await axios.post(`/login`, {
        user_name,
        password,
        remember,
      });

      if (!data) {
        // ERROR
        console.log("ERROR in login");
      }

      // Change the id key to userId
      data.userId = data.id;
      delete data.id;
      dispatch(login(data));
    } catch (err) {
      let oMessage = err.toJSON()["message"].split(" ");
      let code = oMessage[oMessage.length - 1] * 1;
      switch (code) {
        case 400:
          dispatch(setSnackBarMsg("User name and password are required"));
          break;
        case 401:
          dispatch(setSnackBarMsg("Not authorized login"));
          break;
        case 403:
          dispatch(
            setSnackBarMsg(
              "It appears your subscription has expired or didn't start, yet. In order to log in, you must renew your subscription. Please contact contactsupport@asterra.io to renew and continue to access your data."
            )
          );
          break;
        case 404:
          dispatch(setSnackBarMsg("User name and password are required"));
          break;
        default:
          dispatch(
            setSnackBarMsg(
              "We are sorry, please refresh you browser. If this error continues, Please contact contactsupport@asterra.io support."
            )
          );
          break;
      }
    }
  };

  return (
    <Layout>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showSnackBar}
        autoHideDuration={60000000}
        onClose={() => {
          dispatch(toggleShowSnackBar());
        }}
      >
        <Alert
          icon={false}
          onClose={() => {
            dispatch(toggleShowSnackBar());
          }}
          style={style.alert}
        >
          <span style={{ display: "flex", alignItems: "center" }}>
            <img src={icon} style={style.image} />
            {snackBarMsg}
          </span>
        </Alert>
      </Snackbar>
      <Container>
        <LoginColumn>
          <a
            href="https://asterra.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Logo}
              className="img-fluid logo"
              alt="ASTERRA full color logo"
            />
          </a>
          <div className="login">
            <LoginForm loginData={loginCredentials} handleLogin={HandleLogin} />
          </div>
        </LoginColumn>
        <Advertisement />
      </Container>
    </Layout>
  );
};
export default Index;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "index"] }
        language: { in: [$language, "en"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
